
import { defineComponent } from 'vue';
import Select from "@/components/Select.vue";
import deviceService from "@/services/device";
import apiService from "@/api/api";

export default defineComponent({
  name: "Contacts",
  components: {Select},
  data() {
    const selectItems: any[] = []
    return {
      selectItems,
      review: {
        gameid: 0,
        email: '',
        type: 'Other',
        comment: '',
        lang: ''
      },
      truncateSelectValue: false,
      afterReviewScreen: false,
      afterReviewScreenText: 'Thanks for your feedback!'
    }
  },
  beforeMount() {
    this.$store.dispatch('CurrentLangInitialized').then((inited: boolean) => {
      if (inited) {
        apiService.getGamesForSelect(this.$store.getters.URL, this.$store.getters.CURRENT_LANG_ID, deviceService.currentMarket()).then(r => {
          console.log('GAMES FOR SELECT: ', r)
          if (!r.data.error && r.data.gameList) {
            for (let i = 0; i < r.data.gameList.length; i++) {
              this.selectItems.push({id: r.data.gameList[i].id, name: r.data.gameList[i].names[this.$store.getters.CURRENT_LANG_ID], icon: r.data.gameList[i].icon})
            }
            // console.log('SELECT ITEMS: ', this.selectItems)
          } else {
            console.log('ELSE')
          }
        })
      }
    })
  },
  methods: {
    getSrc(image: string): string {
      return apiService.getImageSrc(image, 'games')
    },
    truncateData() {
      this.review = {gameid: 0, email: '', type: '', comment: '', lang: ''}
      this.truncateSelectValue = !this.truncateSelectValue
    },
    selectReviewType(e: any) {
      if (e && e.target && e.target.value) this.review.type = e.target.value
    },
    reviewGameSelected(e: number) {
      if (e && e > 0) this.review.gameid = e
    },
    revTypeIs(str: string): boolean {
      return str === this.review.type
    },
    revTypeID(): number {
      switch (this.review.type) {
        case 'Other':
          return 2
        case 'Proposal for content change':
          return 1
        case 'Report about error':
          return 0
        default:
          return -1
      }
    },
    validateEmail() {
      var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      return re.test(String(this.review.email).toLowerCase())
    },
    sendReview() {
      if (!this.validateEmail()) {
        this.showAfterReviewScreen('Enter valid email!!!')
        return
      }
      const data = {
        gameid: this.review.gameid,
        email: this.review.email,
        type: this.revTypeID(),
        comment: this.review.comment,
        lang: this.$store.getters.CURRENT_LANG
      }
      console.log(JSON.stringify(data))
      apiService.sendReview(data).then(r => {
        console.log('Review: ', r)
        if (!r.data.error) {
          this.truncateData()
          this.showAfterReviewScreen()
        }
      })
    },
    showAfterReviewScreen(text?: string) {
      if (text && text !== '') this.afterReviewScreenText = text
      this.afterReviewScreen = true
      setTimeout(() => {
        this.afterReviewScreen = false
        this.afterReviewScreenText = 'Thanks for your feedback!'
      }, 4000)
    },
  }
})
